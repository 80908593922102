import { Modal } from 'antd';
import AgencyInputText from 'components/common/AgencyInputText';
import AgencySelect from 'components/common/AgencySelect';
import LoaderMu from 'components/common/LoaderMu';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DirectorateListApi, GetCoordinatesApi, GovernorateListApi, VillageListApi, uploadOcrPropertyFile } from 'services/propertyService';
import { dlsKeyGenerateSchema } from 'validations/ValidationSchema';
import { ExclamationCircleFilled } from '@ant-design/icons'
import Timer from 'components/timer/Timer';
import AgencyFileUpload from "components/common/AgencyFileUpload";
const { confirm } = Modal;
function GenerateDlsKeyForm({ dlsFormData, setCoordinatesData, setDlsFormData, setOcrPropertyDetail, isEdit }) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false)
	const [isTimerRunning, setIsTimerRunning] = useState(false)
	// STATES FOR DROPDOWN LIST
	const [governorateList, setGovernorateList] = useState([])
	const [directorateList, setDirectorateList] = useState([])
	const [villageList, setVillageList] = useState([])
	// STATES FOR DROPDOWN SELECTION
	const [selectedGovernorate, setSelectedGovernorate] = useState()
	const [selectedDirectorate, setSelectedDirectorate] = useState()
	const [selectedVillage, setSelectedVillage] = useState()
	// STATE FOR DROPDOWN SEARCH
	const [governorateSearch, setGovernorateSearch] = useState("")
	const [directorateSearch, setDirectorateSearch] = useState("")
	const [villageSearch, setVillageSearch] = useState("")

	// FOR VILLAGE SCROLL PAGINATION
	const [page, setPage] = useState(0)
	const [totalCount, setTotalCount] = useState(0)

	const [titleDeedFile, setTitleDeedFile] = useState(null);
	// FUNCTION FOR FETCH GOVERNORATE LIST
	const getGovernorateList = () => {
		setLoading(true)
		let params = {
			search: governorateSearch ?? '',
			size: 50,
		}
		GovernorateListApi(params).then(response => {
			if (response?.data && response?.data?.length > 0) {
				let governorates = []
				response?.data?.forEach((governorate) => {
					governorates.push({ label: governorate?.governorate, value: governorate?.id })
				})
				setGovernorateList(governorates)
			}
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}
	// GET GOVERNORATE LIST
	useEffect(() => {
		getGovernorateList()
	}, [governorateSearch])

	// FUNCTION FOR FETCH DIRECTORATE LIST
	const getDirectorateList = (isScroll) => {
		setLoading(true)
		let params = {
			search: directorateSearch ?? "",
			size: 50
		}
		let governorateId = parseInt(selectedGovernorate)
		DirectorateListApi(governorateId, params).then(response => {
			if (response?.data && response?.data?.length > 0) {
				let directorates = []
				response?.data?.forEach((directorate) => {
					directorates.push({ label: directorate?.directorate, value: directorate?.id })
				})
				setDirectorateList(directorates)
			}
			// setTotalRowCount(response?.headers["x-total-count"])
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error.response.data.error_description
				? error.response.data.error_description
				: error.response.data?.message)
		})
	}
	// GET DIRECTORATE LIST
	useEffect(() => {
		if (selectedGovernorate && selectedGovernorate !== "") {
			getDirectorateList(false)
		}
	}, [selectedGovernorate, directorateSearch])
	// FUNCTION FOR FETCH VILLAGE LIST
	const getVillageList = () => {
		setLoading(true)
		let params = {
			search: villageSearch ?? '',
			size: 25,
			page: page
		}
		let directorateId = parseInt(selectedDirectorate)
		VillageListApi(directorateId, params).then(response => {
			if (response?.data && response?.data?.length > 0) {
				let villages = []
				response?.data?.forEach((village) => {
					villages.push({ label: village?.village, value: village?.id, villageCode: village?.villageCode })
				})
				if (page === 0) {
					setVillageList(villages)
				} else {
					// Filter out duplicate options before updating the state
					const uniqueNewOptions = new Set([...villageList, ...villages])
					// Convert the Set back to an array (if needed)
					const combinedArray = Array.from(uniqueNewOptions);
					setVillageList(combinedArray)
				}
			}
			setTotalCount(parseInt(response?.headers["x-total-count"] ?? 0))
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message)
		})
	}

	const fileUploadOcrFetchDetail = () => {
		console.log("OCR API called...");
		let formData = new FormData()
		formData.append("file", titleDeedFile);
		setLoading(true);
		uploadOcrPropertyFile(formData).then((response) => {
			setSelectedGovernorate(response.governorateId);
			setSelectedDirectorate(response.directorateId);
			setSelectedVillage({ villageId: response.villageId, villageCode: response.villageCode });
			setInitialValues((prev) => ({
				...prev,
				governorate: response.governorateId,
				directorate: response.directorateId,
				village: response.villageId,
				basin: response.basinNumber,
				district: response.district,
				id_number: response.idNumber
			}))
			setOcrPropertyDetail({
				"governorate": response.governorateId,
				"directorate": response.directorateId,
				"village": response.villageId,
				"basin": response.basinNumber,
				"landType": response.landType,
				"floorNumber": response.floorNumber,
				"buildingNumber": response.buildingNumber,
				"apartmentNumber": response.apartmentNumber,
				"registrationNumber": response.registrationNumber,
				"titleDeedFile": titleDeedFile
			})
			setLoading(false);
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})

	}

	// GET VILLAGE LIST
	useEffect(() => {
		if (selectedDirectorate && selectedDirectorate !== "") {
			getVillageList()
		}
	}, [selectedDirectorate, villageSearch, page])


	// FORM INITIAL VALUES

	const [initialValues, setInitialValues] = useState({
		// IDENTIFICATION
		governorate: dlsFormData?.governorateId ?? "",
		directorate: dlsFormData?.directorateId ?? "",
		village: dlsFormData?.villageId ?? "",
		basin: dlsFormData?.basin ?? "",
		district: dlsFormData?.district ?? "",
		id_number: dlsFormData?.idNumber ?? "",
	})

	useEffect(() => {
		setInitialValues({
			// IDENTIFICATION
			governorate: dlsFormData?.governorateId,
			directorate: dlsFormData?.directorateId,
			village: dlsFormData?.villageId,
			basin: dlsFormData?.basin ?? "",
			district: dlsFormData?.district ?? "",
			id_number: dlsFormData?.idNumber ?? "",
		})
		if (dlsFormData?.governorateId) {
			setSelectedGovernorate(dlsFormData?.governorateId)
		} else {
			setSelectedGovernorate('')
		}
		if (dlsFormData?.governorateId) {
			setSelectedDirectorate(dlsFormData?.directorateId)
		} else {
			setSelectedDirectorate('')
		}
		if (dlsFormData?.villageId) {
			setSelectedVillage({ villageId: dlsFormData?.villageId, villageCode: dlsFormData?.villageCode })
		} else {
			setSelectedVillage({})
		}
	}, [dlsFormData])


	// DLS KEY CONFIRM BEFORE SUBMIT
	const showConfirm = (values, actions) => {

		// CONVERT VILLAGE CODE TO 4 DIGIT LIKE 0004
		let villageCode = selectedVillage?.villageCode;
		let villageCodeString = villageCode.toString();

		if (villageCodeString.length < 4) {
			villageCode = villageCodeString.padStart(4, '0');
		} else {
			villageCode = selectedVillage?.villageCode
		}

		// CONVERT BASIN TO 3 DIGIT LIKE 003
		let basin = values?.basin;
		let basinString = basin.toString();

		if (basinString.length < 3) {
			basin = basinString.padStart(3, '0');
		} else {
			basin = values?.basin
		}

		// CONVERT DISTRICT TO 3 DIGIT LIKE 003 

		let district = values?.district;
		let districtString = district.toString();

		if (districtString.length < 3) {
			district = districtString.padStart(3, '0');
		} else {
			district = values?.district
		}

		// CONVERT ID NUMBER TO 5 DIGIT LIKE 00005 
		let idNumber = values?.id_number;
		let idNumberString = idNumber.toString();
		if (idNumberString.length < 5) {
			idNumber = idNumberString.padStart(5, '0');
		} else {
			idNumber = values?.id_number
		}
		const finalDlsKey = villageCode + basin + district + idNumber
		confirm({
			title: 'Generated DLS key',
			icon: <ExclamationCircleFilled />,
			content: <p>Here is the DLS Key : <span className='fw-bold'>{finalDlsKey}</span>, Village Code : <span className='fw-bold'>{villageCode}</span> derived from the information you provided. Prior to proceeding, kindly verify its accuracy. :  </p>,
			onOk() {
				handleSubmit(values, actions)
			},
			width: 600,
			onCancel() {
				// console.log('Cancel');
			},
		});
	};

	// GENERATE DLS KEY ON SUBMIT AND GET COORDINATES TO DISPLAY ON MAP
	const handleSubmit = (values, actions) => {
		setLoading(true)
		// CONVERT BASIN TO 3 DIGIT LIKE 003 
		const formData = {
			governorateId: parseInt(values?.governorate),
			directorateId: parseInt(values?.directorate),
			villageId: parseInt(values?.village),
			villageCode: parseInt(selectedVillage?.villageCode),
			basin: parseInt(values?.basin),
			district: parseInt(values?.district),
			idNumber: parseInt(values?.id_number)
		}
		setDlsFormData(formData)
		// setCoordinatesData({
		// 	dlsKey: "026200303300704",
		// 	coordinateSystem: "CASSINI",
		// 	coordinate: {
		// 		x: 250022.56099999975,
		// 		y: 1209029.9690000005,
		// 		z: "NaN",
		// 		m: "NaN"
		// 	},
		// 	wgs84: {
		// 		x: 36.060637961760726,
		// 		y: 32.472212813592755,
		// 		z: "NaN",
		// 		m: "NaN"
		// 	}
		// })
		// setIsTimerRunning(true)

		// CALLING GET COORDINATES API
		GetCoordinatesApi(formData).then((response) => {
			setCoordinatesData(response)
			setIsTimerRunning(true)
			setLoading(false)
		}).catch((error) => {
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message)
			setLoading(false)
		})
	}
	const handleVillageScroll = (e) => {
		const { target } = e;
		const lastOption = target.querySelector('.ant-select-item:last-child');
		if (totalCount > villageList?.length) {
			if (lastOption && lastOption.getBoundingClientRect().bottom <= target.getBoundingClientRect().bottom) {
				// User has scrolled to the last option and total count is not available
				setPage((prevPage) => prevPage + 1);
			}
		}
	}

	return (
		<>
			<LoaderMu loading={loading} />
			<Formik
				enableReinitialize={true}
				validationSchema={dlsKeyGenerateSchema}
				initialValues={initialValues}
				onSubmit={(values, actions) => {
					actions.setSubmitting(false);
					showConfirm(values);
				}}>
				{({
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					setFieldValue,
					setFieldError,
					touched,
					isValid,
					errors,
				}) => (
					<Form>
						{isEdit ?
							<div className="form-style1">
								<div className="row"  >
									<div className="col-sm-6 col-xl-4">
										<AgencySelect
											disabled={true}
											showSearch={true}
											filterOption={false}
											label={t("GOVERNORATE")}
											id="governorate"
											placeholder={t("SELECT")}
											name="governorate"
											enableScrollPagination={true}
											handleChange={(value) => {
												setFieldValue("governorate", value);
												setSelectedGovernorate(value)
												setSelectedDirectorate("")
												setSelectedVillage("")
												setCoordinatesData({})
											}}
											handleSearch={(value) => {
												setGovernorateSearch(value)
											}}
											handleBlur={handleBlur}
											value={selectedGovernorate ?? undefined}
											className={`selectpicker form-control ${touched?.governorate &&
												errors?.governorate
												? "error"
												: ""
												}`}
											options={governorateList}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<AgencySelect
											disabled={true}
											showSearch={true}
											filterOption={false}
											label={t("DIRECTORATE")}
											id="directorate"
											placeholder={t("SELECT")}
											name="directorate"
											handleChange={(value) => {
												setFieldValue("directorate", value);
												setSelectedDirectorate(value)
												setVillageList([])
												setSelectedVillage("")
												setCoordinatesData({})
											}}
											handleSearch={(value) => {
												setDirectorateSearch(value)
											}}
											handleBlur={handleBlur}
											value={selectedDirectorate ?? undefined}
											className={`selectpicker form-control ${touched?.directorate &&
												errors?.directorate
												? "error"
												: ""
												}`}
											options={directorateList ?? []}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<AgencySelect
											disabled={true}
											filterOption={false}
											showSearch={true}
											label={t('VILLAGE')}
											id="village"
											placeholder={t("SELECT")}
											name="village"
											handleChange={(value, option) => {
												setFieldValue("village", value);
												setSelectedVillage({ villageId: option?.value, villageCode: option?.villageCode })
												setCoordinatesData({})
											}}
											handleSearch={(value) => {
												setPage(0)
												setVillageSearch(value)
											}}
											handleScroll={(e) => handleVillageScroll(e)}
											handleBlur={handleBlur}
											value={selectedVillage?.villageId}
											className={`selectpicker form-control ${touched?.village && errors?.village
												? "error"
												: ""
												}`}
											options={villageList ?? []}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6 col-xl-4">
										<AgencyInputText
											disabled={true}
											type="number"
											label={t('BASIN')}
											id="basin"
											placeholder={t('BASIN')}
											name="basin"
											handleChange={(event) => {
												setFieldValue("basin", event?.target?.value)
												setCoordinatesData({})
											}}
											handleBlur={handleBlur}
											value={values?.basin}
											className={`form-control ${touched?.basin && errors?.basin
												? "error"
												: ""
												}`}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<AgencyInputText
											disabled={true}
											type="number"
											label={t("DISTRICT")}
											id="district"
											placeholder={t("DISTRICT")}
											name="district"
											// handleChange={handleChange}
											handleChange={(event) => {
												setFieldValue("district", event?.target?.value)
												setCoordinatesData({})
											}}
											handleBlur={handleBlur}
											value={values?.district}
											className={`form-control ${touched?.district && errors?.district
												? "error"
												: ""
												}`}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<AgencyInputText
											disabled={true}
											type="number"
											label={t("ID NUMBER")}
											id="id_number"
											placeholder={t("ID NUMBER")}
											name="id_number"
											// handleChange={handleChange}
											handleChange={(event) => {
												setFieldValue("id_number", event?.target?.value)
												setCoordinatesData({})
											}}
											handleBlur={handleBlur}
											value={values?.id_number}
											className={`form-control ${touched?.id_number && errors?.id_number
												? "error"
												: ""
												}`}
										/>
									</div>
								</div>
							</div> :
							<div className="form-style1">
								<div className="row">
									<div className="col-md-12 col-xl-5">
										{/* TITLE DEED FILE UPLOAD */}
										<AgencyFileUpload
											disabled={isTimerRunning}
											id="titleDeedFile"
											accept=".jpg, .jpeg, .png, .pdf"
											name="titleDeedFile"
											label={t("TITLE DEED")}
											handleChange={(event) => {
												const uploadedFile = event?.target?.files[0];
												const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
												const isAllowedType = allowedFileTypes.includes(uploadedFile?.type);
												const allowedFileSize = 1024 * 1024 * 10; // 10MB
												const isAllowedSize = uploadedFile?.size <= allowedFileSize;

												if (!isAllowedType) {
													setFieldError("titleDeedFile", t("PHOTOS OR PDF MUST BE IN JPEG, PNG, OR PDF FORMAT"));
												} else if (!isAllowedSize) {
													setFieldError("titleDeedFile", t("YOU CAN UPLOAD FILE UP TO 10MB"));
												} else {
													setFieldValue("titleDeedFile", uploadedFile);
													setTitleDeedFile(uploadedFile);
												}
											}}
											error={errors?.titleDeedFile}
											fileName={titleDeedFile || ''}
											className={`form-control p15 pl30 ud-btn2 ${touched?.titleDeedFile && errors?.titleDeedFile ? "error" : ""}`}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<p className="center mb25 mt50">
											<button type="button" className={`py-2 mb-3 ud-btn btn-white`}
												onClick={fileUploadOcrFetchDetail} disabled={titleDeedFile?isTimerRunning?true:false:true}>
												Upload File
											</button>
										</p>
									</div>
								</div>
								<div className="row"  >
									<div className="col-sm-6 col-xl-4">
										<AgencySelect
											disabled={isTimerRunning}
											showSearch={true}
											filterOption={false}
											label={t("GOVERNORATE")}
											id="governorate"
											placeholder={t("SELECT")}
											name="governorate"
											enableScrollPagination={true}
											handleChange={(value) => {
												setFieldValue("governorate", value);
												setSelectedGovernorate(value)
												setSelectedDirectorate("")
												setSelectedVillage("")
												setCoordinatesData({})
											}}
											handleSearch={(value) => {
												setGovernorateSearch(value)
											}}
											handleBlur={handleBlur}
											value={selectedGovernorate ?? undefined}
											className={`selectpicker form-control ${touched?.governorate &&
												errors?.governorate
												? "error"
												: ""
												}`}
											options={governorateList}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<AgencySelect
											disabled={isTimerRunning}
											showSearch={true}
											filterOption={false}
											label={t("DIRECTORATE")}
											id="directorate"
											placeholder={t("SELECT")}
											name="directorate"
											handleChange={(value) => {
												setFieldValue("directorate", value);
												setSelectedDirectorate(value)
												setVillageList([])
												setSelectedVillage("")
												setCoordinatesData({})
											}}
											handleSearch={(value) => {
												setDirectorateSearch(value)
											}}
											handleBlur={handleBlur}
											value={selectedDirectorate ?? undefined}
											className={`selectpicker form-control ${touched?.directorate &&
												errors?.directorate
												? "error"
												: ""
												}`}
											options={directorateList ?? []}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<AgencySelect
											disabled={isTimerRunning}
											filterOption={false}
											showSearch={true}
											label={t('VILLAGE')}
											id="village"
											placeholder={t("SELECT")}
											name="village"
											handleChange={(value, option) => {
												setFieldValue("village", value);
												setSelectedVillage({ villageId: option?.value, villageCode: option?.villageCode })
												setCoordinatesData({})
											}}
											handleSearch={(value) => {
												setPage(0)
												setVillageSearch(value)
											}}
											handleScroll={(e) => handleVillageScroll(e)}
											handleBlur={handleBlur}
											value={selectedVillage?.villageId}
											className={`selectpicker form-control ${touched?.village && errors?.village
												? "error"
												: ""
												}`}
											options={villageList ?? []}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6 col-xl-4">
										<AgencyInputText
											disabled={isTimerRunning}
											type="number"
											label={t('BASIN')}
											id="basin"
											placeholder={t('BASIN')}
											name="basin"
											handleChange={(event) => {
												setFieldValue("basin", event?.target?.value)
												setCoordinatesData({})
											}}
											handleBlur={handleBlur}
											value={values?.basin}
											className={`form-control ${touched?.basin && errors?.basin
												? "error"
												: ""
												}`}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<AgencyInputText
											disabled={isTimerRunning}
											type="number"
											label={t("DISTRICT")}
											id="district"
											placeholder={t("DISTRICT")}
											readOnly={isEdit}
											name="district"
											// handleChange={handleChange}
											handleChange={(event) => {
												setFieldValue("district", event?.target?.value)
												setCoordinatesData({})
											}}
											handleBlur={handleBlur}
											value={values?.district}
											className={`form-control ${touched?.district && errors?.district
												? "error"
												: ""
												}`}
										/>
									</div>
									<div className="col-sm-6 col-xl-4">
										<AgencyInputText
											disabled={isTimerRunning}
											type="number"
											label={t("ID NUMBER")}
											id="id_number"
											placeholder={t("ID NUMBER")}
											name="id_number"
											// handleChange={handleChange}
											handleChange={(event) => {
												setFieldValue("id_number", event?.target?.value)
												setCoordinatesData({})
											}}
											handleBlur={handleBlur}
											value={values?.id_number}
											className={`form-control ${touched?.id_number && errors?.id_number
												? "error"
												: ""
												}`}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 mb-4">
										<div className="d-sm-flex justify-content-end align-items-center">
											{
												isTimerRunning ? <Timer isTimerRunning={isTimerRunning} setIsTimerRunning={setIsTimerRunning} text="You can generate new dls key after : " /> : ""
											}
											{/* <Timer isTimerRunning ={isTimerRunning}  setIsTimerRunning= {setIsTimerRunning}/> */}

											<button
												type="submit"
												className="ud-btn btn-dark"
												onClick={handleSubmit}
												disabled={isTimerRunning}
											>
												{t('GENERATE DLS KEY')}
											</button>
										</div>

									</div>
								</div>

							</div>
						}
					</Form>)}
			</Formik>
		</>
	)
}

export default GenerateDlsKeyForm
