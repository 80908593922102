import { Identification } from "../../components/property/addproperty/Identification";
import Details from "../../components/property/addproperty/Details";
import Amenities from "../../components/property/addproperty/Amenities";
import { TabView, TabPanel } from "primereact/tabview";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Description_media } from "components/property/addproperty/Description_media";
import { GetPropertyData } from "services/propertyService";
import { toast } from "react-toastify";
import LoaderMu from "components/common/LoaderMu";
import { Modal } from "antd";
import { nameBasedProtectedRoutes } from "router/protected";
import PropertyConfirmationModal from "components/property/addproperty/PropertyConfirmationModal";
import UnderConstruction from "components/property/addproperty/UnderConstruction";
import { packageUsageStats } from "services/dashboardService";
import { useSelector } from "react-redux";


export const Addproperty = () => {


  const location = useLocation();

  const navigate = useNavigate()


  const [loading, setLoading] = useState();


  // PROPERTY ID
  const params = new URLSearchParams(location?.search)

  // PROPERTY ID
  const id = params.get('id');
  const tab = params.get('tab')

  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0);


  // CONFIRM WITH USER EITHER PROPERTY IS IN UNDER CONSTRUCTION OR HAVE TITLE DEED
  const [confirmModal, setConfirmModal] = useState(id ? false : true)
  const [isUnderConstruction, setIsUnderConstruction] = useState(false)
  const[isEdit, setIsEdit] = useState(false);

  const [propertyData, setPropertyData] = useState({})
  const [descriptionDataSaved, setDescriptionDataSaved] = useState(false)
  const [detailsDataSaved, setDetailsDataSaved] = useState(false)

  // STATE TO CHECK IF DETAILS TAB WILL SHOW OR NOT
  const [showDetails, setShowDetails] = useState(false)

  // TO CHECK IF PROPERTY API DATA CALLED OR NOT
  const [isApiCalled, setIsApiCalled] = useState(false)

  // GET CURRENT PACKAGE DETAILS
  const currentPackage = useSelector((state) => state?.userPackage)

  // GET CURRENT PACKAGE USAGE
  const getPackageUsage = () => {
    setLoading(true)
    // CALL PACKAGE STATS API
    packageUsageStats().then((response) => {
      const planStatus = currentPackage?.status ?? null;
      if (planStatus === 'ACTIVE' || planStatus === 'INACTIVE') {
        // Check if limits are available for both featured properties and 3D views
        // Comment By Than for testing
         if (
           currentPackage?.noOfActiveProperty <= response?.noOfActiveProperty
         ) {
           navigate(nameBasedProtectedRoutes?.propertyList?.path);
           toast.error(t('PLAN LIMIT EXCEED'));
         }
      } else {
        navigate(nameBasedProtectedRoutes?.propertyList?.path)
        // toast.error('You are not subscribed.');
        // Logic to navigate based on inactive or pending status
      }
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }
  // CALL FUNCTION ON COMPONENT RENDER
  useEffect(() => {
    getPackageUsage()
  }, [])


  // HANDLE PREV STEP NAVIGATION
  const handlePrevStep = (step) => {
    const new_tab = (activeTab + 1) - (step ?? 1)
    setActiveTab((currentTab) => currentTab - (step ?? 1));
    navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${new_tab}${id ? '&id=' + id : ''}`, { replace: true })
  };
  // HANDLE NEXT STEP NAVIGATION
  const handleNextStep = (step) => {
    const new_tab = (activeTab + 1) + (step ?? 1)
    setActiveTab((currentTab) => currentTab + (step ?? 1));
    navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${new_tab}${id ? '&id=' + id : ''}`, { replace: true })
  };

  //FETCH PROPERTY FORM DATA
  const getPropertyFormData = () => {
    setLoading(true)
    GetPropertyData(id).then(response => {
      setPropertyData(response)
      setIsApiCalled(true)
      // SET IF DETAILS TAB WILL SHOW OR NOT
      setShowDetails(response?.showDetail ?? false)
      if (response?.titleDeed && response?.titleDeed !== "") {
        setIsUnderConstruction(false)
      } else {
        setIsUnderConstruction(true)
      }
      if (response?.title && response?.propertyImages?.length > 0) {
        setDescriptionDataSaved(true)
      } else {
        setDescriptionDataSaved(false)
      }
      if (response?.propertyDetail && Object.keys(response?.propertyDetail)?.length > 0) {
        setDetailsDataSaved(true)
      } else {
        setDetailsDataSaved(false)
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error.response?.data?.error_description
        ? error?.response?.data?.error_description
        : error?.response?.data?.message ?? error?.message)
    })
  }

  // IF ID FOUND THEN ONLY GET FORM DATA
  useEffect(() => {
    if (id) {
      getPropertyFormData()
    } else {
      setPropertyData({})
      navigate(nameBasedProtectedRoutes?.propertyAdd?.path, { replace: true })
      setActiveTab(0)
    }
  }, [id])

  const success = (e) => {
    Modal.confirm({
      onOk: () => {
        setActiveTab(e?.index);
        navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${e?.index + 1}${id ? '&id=' + id : ''}`, { replace: true })
      },
      okText: t("PROCEED"),
      cancelText: t("CANCEL"),
      content: t("ENSURE YOU'VE SAVED YOUR DATA BEFORE MOVING FORWARD. ARE YOU READY TO PROCEED?"),
    });
  };

  // CHECK IF IT IS FIRST RENDER OR NOT
  const isFirstRender = useRef(true);
  useEffect(() => {

    if (!isFirstRender?.current) {
      if (tab && isApiCalled === true) {
        switch (tab) {
          case '2': {
            if (!id) {
              toast.error(t("PLEASE COMPLETE IDENTIFICATION FORM BEFORE PROCEED"))
            } else {

              setActiveTab(tab > 0 ? parseInt(tab - 1) : 0)

            }
            break;
          }
          case '3': {
            if (showDetails === true) {
              if (descriptionDataSaved === true) {
                setActiveTab(tab > 0 ? parseInt(tab - 1) : 0)
              } else {
                navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${2}${id ? '&id=' + id : ''}`, { replace: true })
                toast.error(t('PLEASE COMPLETE DESCRIPTION & MEDIA FORM BEFORE PROCEED'))
              }
            } else {
              if (descriptionDataSaved === true) {
                toast.info(t('DETAILS ARE NOT REQUIRED FOR THIS PROPERTY!'))
                const new_tab = tab > 0 ? parseInt(tab) + 1 : 0
                setActiveTab(new_tab)
                navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${new_tab}${id ? '&id=' + id : ''}`, { replace: true })
              } else {
                navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${2}${id ? '&id=' + id : ''}`, { replace: true })
                toast.error(t('PLEASE COMPLETE DESCRIPTION & MEDIA FORM BEFORE PROCEED'))
              }
            }
            break;
          }
          case '4': {
            if (showDetails === true) {
              if (detailsDataSaved === true) {
                setActiveTab(tab > 0 ? parseInt(tab - 1) : 0)
              } else {
                navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${3}${id ? '&id=' + id : ''}`, { replace: true })
                toast.error(t('PLEASE COMPLETE DETAILS FORM BEFORE PROCEED'))
              }
            } else {
              if (descriptionDataSaved === true) {
                setActiveTab(tab > 0 ? parseInt(tab - 1) : 0)
              } else {
                navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${2}${id ? '&id=' + id : ''}`, { replace: true })
                toast.error(t('PLEASE COMPLETE DESCRIPTION & MEDIA FORM BEFORE PROCEED'))
              }
            }
            break;
          }
          default:
            break
        }
      }
    } else {
      // For the initial render, set the ref to false
      isFirstRender.current = false;
    }
  }, [tab, isApiCalled])

  const handleCancel = () => {
    setConfirmModal(false)
    navigate(-1)
  }





  return (
    <div className="dashboard__content property-page bgc-f7">
      {/* PROPERTY CONFIRMATION MODAL */}
      <PropertyConfirmationModal confirmModal={confirmModal} handleCancel={handleCancel} setConfirmModal={setConfirmModal} setUnderConstruction={setIsUnderConstruction} />
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("ADD NEW PROPERTY")}</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 pt30 mb30 overflow-hidden position-relative tabView ">
            <TabView
              className="pr_tabView"
              activeIndex={activeTab}
              onTabChange={(e) => {
                if (activeTab < e?.index) {
                  switch (e?.index) {
                    case 1: {
                      if (id) {
                        success(e)
                      } else {
                        toast.error(t("PLEASE COMPLETE IDENTIFICATION FORM BEFORE PROCEED"))
                      }
                      break;
                    }
                    case 2: {
                      if (descriptionDataSaved) {
                        success(e)
                      } else {
                        toast.error(t("PLEASE COMPLETE DESCRIPTION & MEDIA FORM BEFORE PROCEED"))
                      }
                      break;
                    }
                    case 3: {
                      if (showDetails === true) {
                        if (detailsDataSaved) {
                          success(e)
                        } else {
                          toast.error(t('PLEASE COMPLETE DETAILS FORM BEFORE PROCEED'))
                        }
                      } else {
                        if (descriptionDataSaved) {
                          success(e)
                        } else {
                          toast.error(t("PLEASE COMPLETE DESCRIPTION & MEDIA FORM BEFORE PROCEED"))
                        }
                        break;
                      }
                      break;
                    }
                  }
                } else {
                  setActiveTab(e?.index);
                  navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${e?.index + 1}${id ? '&id=' + id : ''}`, { replace: true })
                }
              }}
            >
              <TabPanel
                header={`1. ${t("IDENTIFICATION")}`}
                headerClassName="pr_tabHeader me-2"
              >
                {/* IF PROPERTY IS IN UNDER CONSTRUCTION THEN GO WITH UNDER CONSTRUCTION ELSE NORMAL FLOW */}
                {
                  isUnderConstruction ?
                    <UnderConstruction
                      handleNextStep={handleNextStep}
                      property_id={id}
                      propertyData={propertyData}
                      getPropertyData={getPropertyFormData}
                      isEdit={false} />
                    : <Identification
                      handleNextStep={handleNextStep}
                      property_id={id}
                      propertyData={propertyData}
                      getPropertyData={getPropertyFormData}
                    />
                }


              </TabPanel>
              <TabPanel
                header={`2. ${t("DESCRIPTION & MEDIA")}`}
                headerClassName="pr_tabHeader me-2"
              >
                <Description_media
                  handlePrevStep={handlePrevStep}
                  handleNextStep={handleNextStep}
                  property_id={id}
                  propertyData={propertyData}
                  getPropertyData={getPropertyFormData}
                  setDescriptionDataSaved={setDescriptionDataSaved}
                />
              </TabPanel>

              <TabPanel
                header={`3. ${t("DETAILS")}`}
                headerClassName="pr_tabHeader me-2"
                disabled={!showDetails}
              >
                <Details
                  property_id={id}
                  propertyData={propertyData}
                  handlePrevStep={handlePrevStep}
                  handleNextStep={handleNextStep}
                  getPropertyData={getPropertyFormData}
                  setDetailsDataSaved={setDetailsDataSaved}
                />
              </TabPanel>
              <TabPanel
                header={`4. ${t("AMENITIES")}`}
                headerClassName="pr_tabHeader me-2"
              >
                <Amenities
                  property_id={id}
                  handlePrevStep={handlePrevStep}
                  amenities={propertyData?.propertyAmenities}
                  getPropertyData={getPropertyFormData}
                  showDetails={showDetails}
                />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>
  );
};
