import React, { useEffect, useState } from "react";
import AgencyInputText from "../../common/AgencyInputText";
import AgencySelect from "../../common/AgencySelect";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { getPropertyDetailsFields, savePropertyDetailsApi, editPropertyDetailsApi } from "services/propertyService";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import LoaderMu from "components/common/LoaderMu";
import AgencyDatePicker from "components/common/AgencyDatePicker";
import { detailsFormSchema } from "validations/ValidationSchema";
import { FloorNoDropdownList } from "jsonData/StaticData";
function filterNonNullValues(object) {
  const result = {};
  for (const key in object) {
    if (object.hasOwnProperty(key) && object[key] !== null) {
      result[key] = object[key];
    }
  }
  return result;
}
function Details({ property_id, propertyData, handlePrevStep, handleNextStep, getPropertyData, setDetailsDataSaved, isEdit }) {

  const { t } = useTranslation()
  const { i18n } = useTranslation()

  const cookies = new Cookies()
  const langKey = i18n?.language ?? cookies.get("langKey") ?? 'en'
  const [loading, setLoading] = useState(false)
  const [formFields, setFormFields] = useState([])
  const [initialValues, setInitialValues] = useState({})

  const handleSubmit = (values) => {
    setLoading(true)

    // GET FORM DATA AND ADD ID , PROPERTY  ID
    const formData = { id: null, propertyId: property_id, ...values }

    // CONVERT VALUES TO FLOAT , INTEGER AND BOOLEAN
    const convertedFields = Object.entries(formData).reduce((acc, [key, value]) => {
      switch (key) {
        case "id":
        case "availableFrom":
          if (typeof value === 'object' && value instanceof Date && key === "availableFrom") {
            acc[key] = value.toISOString(); // Convert date to string using ISO format
          } else {
            acc[key] = value; // Keep as-is
          }
          // acc[key] = value; // Keep as-is
          break;
        case "apartmentId":
        case "buildingNumber":
          acc[key] = value
          break;
        case "propertyId":
        case "yearOld":
        case "noOfBedRooms":
        case "noOfBathRooms":
        case "floorNumber":
        case "noOfApartmentInBuilding":
        case "totalFloor":
          acc[key] = parseInt(value); // Convert to integer
          break;
        case "areaSqm":
        case "gardenSize":
        case "landAreaSqm":
          acc[key] = parseFloat(value); // Convert to float
          break;
        case "furnished":
        case "garden":
          acc[key] = Boolean(value); // Convert to boolean
          break;
      }
      return acc;
    }, {});
    // SUBMIT FINAL DATA TO API
    console.log("Detail Edit API Called........", isEdit);
    if(isEdit){
      editPropertyDetailsApi(convertedFields).then((response) => {
        getPropertyData()
        setDetailsDataSaved(true)
        handleNextStep(1)
        setLoading(false)
        toast.success(response?.message ?? 'Details Data updated')
      }).catch((error) => {
        setLoading(false)
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      }).finally(()=>{
        setLoading(false)
      })
    }else{
      savePropertyDetailsApi(convertedFields).then((response) => {
        getPropertyData()
        setDetailsDataSaved(true)
        handleNextStep(1)
        setLoading(false)
        toast.success(response?.message ?? 'Details Data saved')
      }).catch((error) => {
        setLoading(false)
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      }).finally(()=>{
        setLoading(false)
      })
    }
  }
  // CALLING API FOR GET ALL FIELDS
  const propertyDetailsField = () => {
    setLoading(true)
    getPropertyDetailsFields(propertyData?.propertyType?.id).then((response) => {

      const visible_fields = response?.propertyDetail.filter((element) => element?.visible === true)
      
      const filteredObject = filterNonNullValues(propertyData?.propertyDetail)
      if (propertyData?.propertyDetail && Object.keys(filteredObject)?.length > 0) {
        const resultObject = response?.propertyDetail?.reduce((accumulator, currentItem) => {
          if (currentItem?.visible === true) {
            accumulator[currentItem?.field_name] = filteredObject[currentItem?.field_name] ?? '';
          }
          return accumulator;
        }, {});

        if(filteredObject.hasOwnProperty('garden') && filteredObject['garden']===false){
          let newVisibleFields = [];
          visible_fields.map((field)=>{
            if(field.field_name!=='gardenSize'){
              newVisibleFields.push(field);
            }
          })
          setFormFields(newVisibleFields);
        }else{
          setFormFields(visible_fields ?? [])
        }
        setInitialValues(resultObject);
      } else {
        setFormFields(visible_fields ?? [])
        const resultObject = response?.propertyDetail?.reduce((accumulator, currentItem) => {
          if (currentItem?.visible === true) {
            accumulator[currentItem?.field_name] = '';
          }
          return accumulator;
        }, {});

        setInitialValues(resultObject);
      }



      setLoading(false)

      // setAmenitiesList(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(error?.response?.data?.error_description
        ? error?.response?.data?.error_description
        : error?.response?.data?.message ?? error?.message)
    })
  }

  useEffect(() => {
    if (property_id && propertyData?.propertyType?.id) {
      propertyDetailsField()
    }
  }, [property_id, propertyData])


  return (
    <div className="ps-widget bgc-white bdrs12 p30 overflow-hidden position-relative">
      <LoaderMu loading={loading} />
      <h4 className="title fz17 mb30">{t("LISTING LOCATION")}</h4>

      {
        formFields && formFields?.length > 0 ?
          <Formik
            initialValues={initialValues}
            validationSchema={detailsFormSchema(formFields, langKey)}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              handleSubmit(values);
            }}>
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              setFieldError,
              touched,
              isValid,
              errors,
            }) => (
              <Form>
                <div className="form-style1">
                  <div className="row">
                    {
                      formFields && formFields?.length > 0
                        ?
                        formFields?.map((field, index) => {

                          if (field.visible !== false) {
                            if (field?.field_type === "Number") {

                              if (field?.field_name === "floorNumber") {

                                const floorList = FloorNoDropdownList?.map((floor) => ({ label: floor?.label[langKey], value: floor?.value }))

                                return <div key={index + 1} className="col-sm-6 col-xl-4">
                                  <AgencySelect
                                    label={field?.field_level[langKey]}
                                    id={field?.field_name}
                                    placeholder={field?.field_level[langKey]}
                                    name={field?.field_name}
                                    handleChange={(value) => setFieldValue(field?.field_name, value)}
                                    handleBlur={handleBlur}
                                    value={values[field?.field_name]}
                                    className={`selectpicker form-control ${touched[field?.field_name] && errors[field?.field_name]
                                      ? "error"
                                      : ""
                                      }`}
                                    options={floorList}
                                  />
                                </div>
                              } else {
                                return <div key={index + 1} className="col-sm-6 col-xl-4">
                                  <AgencyInputText
                                    type="string"
                                    label={field?.field_level[langKey]}
                                    id={field?.field_name}
                                    placeholder={field?.field_level[langKey]}
                                    name={field?.field_name}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values[field?.field_name]}
                                    className={`form-control ${errors[field?.field_name] && touched[field?.field_name]
                                      ? "error"
                                      : ""
                                      }`}
                                  />
                                  {/* {errors[field?.field_name] && (
                                      <div className="error-message">{errors[field?.field_name]}</div>
                                    )} */}
                                </div>
                              }

                            }
                            if (field?.field_type === "Boolean" && (field?.field_name === "furnished" || field?.field_name === "garden")) {
                              return <div key={index + 1} className="col-sm-6 col-xl-4">
                                <AgencySelect
                                  label={field?.field_level[langKey]}
                                  id={field?.field_name}
                                  placeholder={field?.field_level[langKey]}
                                  name={field?.field_name}
                                  handleChange={(value) => setFieldValue(field?.field_name, value)}
                                  handleBlur={handleBlur}
                                  value={values[field?.field_name]}
                                  className={`selectpicker form-control ${touched[field?.field_name] && errors[field?.field_name]
                                    ? "error"
                                    : ""
                                    }`}
                                  options={[
                                    // { value: '', label: 'Select' },
                                    { value: true, label: t("YES") },
                                    { value: false, label: t("NO") },
                                  ]}
                                />
                              </div>
                            }

                            if (field?.field_type === "Date") {

                              return <div key={index + 1} className="col-sm-6 col-xl-4">
                                <AgencyDatePicker
                                  label={field?.field_level[langKey]}
                                  placeholder={field?.field_level[langKey]}
                                  id={field?.field_name}
                                  name={field?.field_name}
                                  handleChange={(event) => {
                                    setFieldValue(field?.field_name, event.target.value)

                                  }}
                                  handleBlur={handleBlur}
                                  className={"custom-datepicker"}
                                  value={values[field?.field_name] ? new Date(values[field?.field_name]) : values[field?.field_name]}
                                />
                              </div>
                            }
                          }
                        })
                        : ""}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-sm-flex justify-content-between">
                        <button
                          className="ud-btn btn-white"
                          type="button"
                          onClick={() => {
                            handlePrevStep();
                          }}
                        >
                          {t("PREV STEP")}
                          <i className="fal fa-arrow-right-long" />
                        </button>
                        <button
                        disabled={loading}
                          type="submit"
                          className="ud-btn btn-dark"
                          onClick={handleSubmit}
                        >
                          {t("NEXT STEP")}
                          <i className="fal fa-arrow-right-long" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>)}
          </Formik> : ""
      }

    </div>
  );
}

export default Details;
