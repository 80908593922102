import { Form, Formik } from "formik";
import Addagentimg from "../../assets/images/team/agent-6.jpg";
import AgencyFileUpload from "components/common/AgencyFileUpload";
import AgencyInputText from "components/common/AgencyInputText";
import AgencyTextArea from "components/common/AgencyTextArea";
import { addAgentSchema } from "validations/ValidationSchema";
import { createAgent } from "services/agentService";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import LoaderMu from "components/common/LoaderMu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "router/protected";
import { packageUsageStats } from "services/dashboardService";
export const Addagent = () => {


  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)


  const currentPackage = useSelector((state) => state?.userPackage)


  const navigate = useNavigate()
  // GET USER DATA FROM REDUX
  const userData = useSelector((state) => state?.user?.userData)
  const initialValues = {
    profile_picture: "",
    firstName: "",
    lastName: "",
    email: "",
    agency: userData?.agencyName,
    address: "",
    facebook_url: "",
    instagram_url: "",
    linkedin_url: "",
    about_en: "",
    about_ar: "",
    password: "",
    confirm_password: ""
  }

  const handleSubmit = (values, action) => {
    setLoading(true)
    const formdata = new FormData()
    // formdata.append("agencyId")
    formdata.append("firstName", values?.firstName)
    formdata.append("lastName", values?.lastName)
    formdata.append("email", values?.email)
    formdata.append("address", values?.address)
    formdata.append("facebookUrl", values?.facebook_url)
    formdata.append("instagramUrl", values?.instagram_url)
    formdata.append("linkedInUrl", values?.linkedin_url)
    formdata.append("about['en']", values?.about_en)
    formdata.append("about['ar']", values?.about_ar)
    formdata.append("password", values?.password)

    if (values?.profile_picture) {
      formdata.append("profilePicture", values?.profile_picture)
    }

    //CALLING CREATE NEW AGENT API
    createAgent(formdata).then((response) => {
      setLoading(false);
      action.resetForm()
      navigate(nameBasedProtectedRoutes?.agentListing?.path)
      toast.success(response?.message ?? 'Agent created!')
    }).catch((error) => {
      setLoading(false);
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  // GET PACKAGE USAGE DATA FOR CHECK THE AVAILABLE PACKAGE LIMIT TO ADD AGENT


  const getPackageUsage = () => {
    setLoading(true)
    // CALL PACKAGE STATS API
    packageUsageStats().then((response) => {

      const planStatus = currentPackage?.status ?? null;

      if (planStatus === 'ACTIVE' || planStatus === 'INACTIVE') {
        // Check if limits are available for both featured properties and 3D views
        //Comment By Than for testing
        if (
           currentPackage?.noOfActiveAgent <= response?.noOfActiveAgent
         ) {
           navigate(nameBasedProtectedRoutes?.agentListing?.path);
           toast.error("PLAN LIMIT EXCEED");
         }
      } else {
        navigate(nameBasedProtectedRoutes?.agentListing?.path)
        toast.error(t('CURRENT_SUBSCRIPTION_NOT_FOUND'));
        // toast.error('You are not subscribed.');
        // Logic to navigate based on inactive or pending status
      }


    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }
  // CALL FUNCTION ON COMPONENT RENDER
  useEffect(() => {
    getPackageUsage()
  }, [])


  return (
    <div className="dashboard__content property-page bgc-f7">
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("ADD AGENT")}</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <LoaderMu loading={loading} />
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <Formik
              initialValues={initialValues}
              validationSchema={addAgentSchema}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                handleSubmit(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                setFieldError,
                touched,
                isValid,
                errors,
              }) => (
                <Form>
                  <div className="col-lg-12 form-style1">
                    <div className="row">
                      <div className="col-xl-7">
                        <div className="profile-box position-relative d-md-flex align-items-end mb50">
                          <div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm">
                            <img className="w-100" src={Addagentimg} alt="Agent" />
                          </div>
                          {/* UPLOAD AGENT PROFILE PICTURE */}
                          <div className="profile-content ml30 ml0-sm">
                            <AgencyFileUpload
                              id="profile_picture"
                              accept=".jpg, .jpeg, .png"
                              name="profile_picture"
                              handleChange={(event) => {
                                const uploadedFile = event?.target?.files[0]
                                const allowedFileTypes = ["image/jpeg", "image/png"];
                                const isAllowedType = allowedFileTypes.includes(uploadedFile?.type);
                                if (!isAllowedType) {
                                  setFieldError("profile_picture", "You can only upload JPG or PNG files!")
                                } else {
                                  setFieldValue("profile_picture", event?.target?.files[0])
                                }
                              }}
                              fileName={values?.profile_picture}
                              className={`form-control p15 pl30 ud-btn2 ${touched?.profile_picture &&
                                errors?.profile_picture
                                ? "error"
                                : ""
                                }`}
                            />
                            <p className="text">{t("PHOTOS MUST BE JPEG OR PNG FORMAT")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* AGENT FIRST NAME */}
                      <div className="col-sm-6 col-xl-4">
                        <AgencyInputText
                          type="text"
                          label={t("FIRST NAME")}
                          id="firstName"
                          placeholder={t("FIRST NAME")}
                          name="firstName"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.firstName}
                          className={`form-control ${touched?.firstName && errors?.firstName
                            ? "error"
                            : ""
                            }`}
                        />
                      </div>
                      {/* <div className="col-sm-6 col-xl-4">
                          <div className="row">
                            <div className="col-md-4 col-xl-4">
                              <InputPhoneCode
                                id="phoneCode"
                                name="phoneCode"
                                type="number"
                                label="Code"
                                country="jo"
                                placeholder="Code"
                                handleChange={(code)=>{setFieldValue("phoneCode",code)}}
                                handleBlur={handleBlur}
                                value={values?.phoneCode}
                                className={` ${touched?.phoneCode && errors?.phoneCode ? "error" : ""
                                  }`}
                              />
                            </div>
                            <div className="col md-8 col-xl-8">
                              <AgencyInputText
                                id="phoneNo"
                                name="phoneNo"
                                type="number"
                                label="Phone No."
                                placeholder="Your Phone Number"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values?.phoneNo}
                                className={`form-control ${touched?.phoneNo && errors?.phoneNo ? "error" : ""
                                  }`}
                              />
                            </div>
                          </div>
                        </div> */}
                      {/* AGENT LAST NAME */}
                      <div className="col-sm-6 col-xl-4">
                        <AgencyInputText
                          type="text"
                          label={t("LAST NAME")}
                          id="lastName"
                          placeholder={t("LAST NAME")}
                          name="lastName"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.lastName}
                          className={`form-control ${touched?.lastName && errors?.lastName ? "error" : ""
                            }`}
                        />
                      </div>
                      {/* AGENT EMAIL */}
                      <div className="col-sm-6 col-xl-4">
                        <AgencyInputText
                          type="email"
                          label={t("EMAIL")}
                          id="email"
                          placeholder={t("EMAIL")}
                          name="email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.email}
                          className={`form-control ${touched?.email && errors?.email ? "error" : ""
                            }`}
                        />
                      </div>
                      {/* AGENCY SELECTED BY DEFAULT */}
                      <div className="col-sm-6 col-xl-4">
                        <AgencyInputText
                          type="text"
                          label={t("AGENCY")}
                          id="agency"
                          placeholder={t("AGENCY")}
                          name="agency"
                          handleChange={handleChange}
                          readOnly={true}
                          handleBlur={handleBlur}
                          value={values?.agency}
                          className={`form-control ${touched?.agency && errors?.agency ? "error" : ""
                            }`}
                        />
                      </div>
                      {/* AGENT ADDRESS */}
                      <div className="col-sm-6 col-xl-4">
                        <AgencyInputText
                          type="text"
                          label={t("ADDRESS")}
                          id="address"
                          placeholder={t("ADDRESS")}
                          name="address"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.address}
                          className={`form-control ${touched?.address && errors?.address ? "error" : ""
                            }`}
                        />
                      </div>
                      {/* AGENT FACEBOOK */}
                      <div className="col-sm-6 col-xl-4">
                        <AgencyInputText
                          type="url"
                          label={t("FACEBOOK URL")}
                          id="facebook_url"
                          placeholder={t("FACEBOOK URL")}
                          name="facebook_url"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.facebook_url}
                          className={`form-control ${touched?.facebook_url && errors?.facebook_url
                            ? "error"
                            : ""
                            }`}
                        />
                      </div>
                      {/* AGENT INSTAGRAM */}
                      <div className="col-sm-6 col-xl-4">
                        <AgencyInputText
                          type="url"
                          label={t("INSTAGRAM URL")}
                          id="instagram_url"
                          placeholder={t("INSTAGRAM URL")}
                          name="instagram_url"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.instagram_url}
                          className={`form-control ${touched?.instagram_url && errors?.instagram_url
                            ? "error"
                            : ""
                            }`}
                        />
                      </div>
                      {/* AGENT LINKEDIN */}
                      <div className="col-sm-6 col-xl-4">
                        <AgencyInputText
                          type="url"
                          label={t("LINKEDIN URL")}
                          id="linkedin_url"
                          placeholder={t("LINKEDIN URL")}
                          name="linkedin_url"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.linkedin_url}
                          className={`form-control ${touched?.linkedin_url && errors?.linkedin_url
                            ? "error"
                            : ""
                            }`}
                        />
                      </div>
                      {/* ABOUT AGENT IN ENGLISH */}
                      <div className="col-md-12">
                        <AgencyTextArea
                          rows={4}
                          cols={30}
                          label={t("ABOUT AGENT (ENGLISH)")}
                          id="about_en"
                          placeholder={t("ABOUT AGENT")}
                          name="about_en"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.about_en}
                          className={` ${touched?.about_en && errors?.about_en
                            ? "error"
                            : ""
                            }`}
                        />

                      </div>
                      {/* ABOUT AGENT IN ARABIC */}
                      <div className="col-md-12">
                        <AgencyTextArea
                          rows={4}
                          cols={30}
                          dir={'rtl'}
                          label={t("ABOUT AGENT (ARABIC)")}
                          id="about_ar"
                          placeholder={t("ABOUT AGENT (ARABIC)")}
                          name="about_ar"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.about_ar}
                          className={` ${touched?.about_ar && errors?.about_ar
                            ? "error"
                            : ""
                            }`}
                        />
                        {/* AGENT PASSWORD AND USERNAME */}
                      </div>
                      <h4 className="title fz17 mb30 mt30">
                        {t("CREATE PASSWORD")}
                      </h4>
                      <div className="row">
                        {/* CREATE PASSWORD */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="password"
                            label={t("ENTER PASSWORD")}
                            id="password"
                            placeholder={t("PASSWORD")}
                            name="password"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.password}
                            className={`form-control ${touched?.password && errors?.password
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        {/* CONFIRM PASSWORD */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="password"
                            label={t("CONFIRM PASSWORD")}
                            id="confirm_password"
                            placeholder={t("CONFIRM PASSWORD")}
                            name="confirm_password"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.confirm_password}
                            className={`form-control ${touched?.confirm_password && errors?.confirm_password
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                      </div>
                      {/* SUBMIT BUTTON FOR ADD AGENT */}
                      <div className="col-md-12">
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="ud-btn btn-dark"
                            onClick={handleSubmit}
                          >
                            {t("ADD AGENT")}
                            <i className="fal fa-arrow-right-long" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
