import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const FacebookPixelInit = () => {
  useEffect(() => {
    const options = {
      autoConfig: true,  // Auto-configure the Pixel
      debug: false       // Disable debugging logs in console
    };

    // ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, {}, options);
    ReactPixel.init('1039748294675102', {}, options);
    ReactPixel.pageView();  // Track initial page load
  }, []);

  return null;  // No UI rendering, just initialization
};

export default FacebookPixelInit;
