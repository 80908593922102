import React, { useEffect, useRef, useState } from 'react'
import { FormLabel } from 'react-bootstrap';
import markerImg from "../../assets/images/resource/map-marker-2.png"
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function MapMarkerDrag({ setMarkerCoordinates, markerCoordinates, setSelectedAddress, selectedPlace }) {

    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const [mapState, setMap] = useState(null);
    const [isDragged, setIsDragged] = useState(false);
    const { t } = useTranslation()


    // Coordinates for the bounding box of Jordan
    const jordanBounds = {
        north: 33.3736, // Northernmost point
        south: 29.1836, // Southernmost point
        east: 39.3018,  // Easternmost point
        west: 34.9579   // Westernmost point
    };

    const jordanCenter = markerCoordinates ?? { lat: 30.5852, lng: 36.2384 }

    useEffect(() => {
        const initMap = async () => {

            const { Map } = await window.google.maps.importLibrary('maps');
            const { AdvancedMarkerElement } = await window.google.maps.importLibrary('marker');

            const map = new Map(mapRef.current, {
                minZoom: 6.8,
                center: { lat: 31.5, lng: 36.5 },
                restriction: {
                    latLngBounds: jordanBounds
                },
                mapId: '4504f8b37365c3d0',
            });

            setMap(map);

            const markerIcon = document.createElement("img");
            markerIcon.src = markerImg

            const markerOptions = {
                map,
                content: markerIcon,
                gmpDraggable: true,
                position: jordanCenter
            }

            const marker = new AdvancedMarkerElement(markerOptions);

            // EVENT ON MARKER DRAG END
            marker.addListener('dragend', (evt) => {
                const newPosition = evt.latLng;
                // Check if the new position is within bounds
                if (!bounds.contains(newPosition)) {
                    // Reset marker position to center of Jordan
                    marker.position = jordanCenter
                    toast.warning(t("MARKER MUST REMAIN WITHIN THE BOUNDS OF JORDAN"));
                } else {
                    const newLat = evt.latLng.lat().toFixed(3);
                    const newLng = evt.latLng.lng().toFixed(3);
                    // Prevent the second useEffect from running
                    setIsDragged(true);
                    setMarkerCoordinates({ lat: parseFloat(newLat), lng: parseFloat(newLng) })
                    // map.setZoom(8);  // Set the zoom level
                    const geocoder = new window.google.maps.Geocoder();

                    // Create a LatLng object
                    const latLng = { lat: parseFloat(newLat), lng: parseFloat(newLng) };
                    geocoder.geocode({ location: latLng }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            const placeName = results[0].formatted_address;
                            setSelectedAddress(placeName);
                        } else {
                            console.error("Geocoding failed due to:", status);
                        }
                    });
                }
            });

            // Define the bounds of Jordan
            const bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(jordanBounds.south, jordanBounds.west), // Southwest corner
                new window.google.maps.LatLng(jordanBounds.north, jordanBounds.east)  // Northeast corner
            );

            // Fit the map to the bounds of Jordan
            map.fitBounds(bounds);

            // Store marker reference for future use
            markerRef.current = marker;
        };
        if (window.google) {
            initMap();
        }

    }, []);


    // Update marker coordinates on selectedPlace change
    useEffect(() => {
        if (mapState && selectedPlace && markerRef.current && !isDragged) {


            const newLat = selectedPlace.geometry.location.lat().toFixed(3);
            const newLng = selectedPlace.geometry.location.lng().toFixed(3);

            setMarkerCoordinates({ lat: parseFloat(newLat), lng: parseFloat(newLng) })
            mapState.setZoom(12.5)
            mapState.setCenter({ lat: parseFloat(newLat), lng: parseFloat(newLng) })
            markerRef.current.position = { lat: parseFloat(newLat), lng: parseFloat(newLng) }
            // Update marker position
            // markerRef.current.position = { lat:newLat,lng: newLng };

            // Update map center
            // markerRef.current.setCenter({ lat: newLat, lng: newLng });
        }
        // Reset the dragged flag after executing
        setIsDragged(false);
    }, [selectedPlace]);

    // Clean up event listeners on component unmount
    useEffect(() => {
        return () => {
            if (mapState) {
                window.google.maps.event.clearInstanceListeners(mapState);
            }
        };

    }, [mapState]);


    return <div>
        <FormLabel className="heading-color ff-heading fw600 mb10">
            {t("DRAG MARKER AND SELECT A LOCATION FOR YOUR PROPERTY")}
        </FormLabel>
        <div id="map" ref={mapRef} style={{ height: '400px', width: '100%' }} />
    </div>
}

export default MapMarkerDrag
