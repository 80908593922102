import React, { useEffect, useState } from "react";
import AgencyInputText from "../../../common/AgencyInputText";
import AgencySelect from "../../../common/AgencySelect";
import { governorateOption } from "../../../../assets/dummyData/DemoData";
import AgencyFileUpload from "components/common/AgencyFileUpload";
import { Form, Formik } from "formik";
import { propertyIdentificationSchema } from "validations/ValidationSchema";
import { useTranslation } from 'react-i18next';
import GenerateDlsKeyForm from "./GenerateDlsKeyForm";
import MapMarker from "components/map/MapMarker";
import { PropertyInfoApi, PropertyListApi, SaveIdentifcationFormApi, EditIdentificationData } from "services/propertyService";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LoaderMu from "components/common/LoaderMu";
import { FloorNoDropdownList } from "jsonData/StaticData";
import Cookies from "universal-cookie";
import { FiEye } from "react-icons/fi";
import ViewFileModal from "components/common/ViewFileModal";
import { nameBasedProtectedRoutes } from "router/protected";
export const Identification = ({ property_id, propertyData, getPropertyData, isEdit }) => {

  const navigate = useNavigate()

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const cookies = new Cookies()
  const langKey = i18n?.language ?? cookies.get("langKey") ?? 'en'

  const [loading, setLoading] = useState(false)
  const [propertyTypeList, setPropertyTypeList] = useState([])
  const [propertyInfo, setPropertyInfo] = useState({})
  const [coordinatesData, setCoordinatesData] = useState({})
  const [prevTitleDeed, setPrevTitleDeed] = useState(null)
  // SELECTED PROPERTY CATEGORY
  const [selectedPropCategory, setSelectedPropertyCategory] = useState('')
  const [selectedPropType, setSelectedPropType] = useState('')

  const [propertyId, setPropertyId] = useState(property_id ?? '')
  const [dlsFormData, setDlsFormData] = useState({})


  const floorList = FloorNoDropdownList?.map((floor) => ({ label: floor?.label[langKey], value: floor?.value }))

  const [viewFile, setViewFile] = useState(false) // TITLE DEED PREV FILE VIEW MODAL

  // FORM INITIAL VALUES
  // IDENTIFICATION
  const [initialValues, setInitialValues] = useState({
    id: null,
    titleDeedFile: "",
    buildingNumber: "",
    floorNumber: "",
    apartmentId: "",
    land_type: "",
    propertyOfferingType: '',
    propertyCategory: "",
    propertyTypeId: "",
    priceInJOD: "",
    agencyRefNo: "",
    amakenId: "",
    listing_date: moment().format('YYYY-MM-DD'),
  })

  //--------OCR Detail Object-------------
  const [ocrPropertyDetail, setOcrPropertyDetail] = useState({
    "governorate": "",
    "directorate": "",
    "village": "",
    "basin": "",
    "floorType": "",
    "buildingNumber": "",
    "apartmentNumber": "",
    "titleDeedFile": null
  })
  // HANDLE IDENTIFICATION FORM SUBMIT ON CLICK ON NEXT
  const handleSubmit = (values) => {

    setLoading(true)
    let formData = new FormData()
    let editFormData = {};

    if (isEdit) {
      editFormData = {
        id: isEdit ? property_id : propertyData?.id ?? null,
        propertyOfferingType: values?.propertyOfferingType,
        propertyCategory: values?.propertyCategory,
        propertyTypeId: values?.propertyTypeId,
        priceInJOD: values?.priceInJOD,
        listingDate: moment().format('YYYY-MM-DD'),
        agencyRefNo: values?.agencyRefNo,
      };
    } else {
      // ID IF EDIT FORM 
      formData.append("id", propertyId ?? null)

      formData.append("governorateId", dlsFormData?.governorateId ? parseInt(dlsFormData?.governorateId) : 0)
      formData.append("directorateId", dlsFormData?.directorateId ? parseInt(dlsFormData?.directorateId) : 0)
      formData.append("villageId", dlsFormData?.villageId ? parseInt(dlsFormData?.villageId) : 0)
      formData.append("villageCode", dlsFormData?.villageCode ? parseInt(dlsFormData?.villageCode) : 0)
      formData.append("basin", dlsFormData?.basin ? parseInt(dlsFormData?.basin) : 0)
      formData.append("district", dlsFormData?.district ? parseInt(dlsFormData?.district) : 0)
      formData.append("idNumber", dlsFormData?.idNumber ? parseInt(dlsFormData?.idNumber) : 0)

      formData.append("buildingNumber", values?.buildingNumber)
      formData.append("floorNumber", values?.floorNumber ?? null)
      formData.append("apartmentId", values?.apartmentId)
      formData.append("propertyOfferingType", values?.propertyOfferingType)
      formData.append("propertyCategory", values?.propertyCategory)
      formData.append("propertyTypeId", values?.propertyTypeId)

      formData.append("priceInJOD", parseInt(values?.priceInJOD))
      formData.append("amakenId", values?.amakenId)

      //ADD AGENCY REFERENCE NUMBER
      if (values?.agencyRefNo) {
        formData.append("agencyRefNo", values?.agencyRefNo)
      }
      // TITLE DEED FILE UPLOAD
      // if (values?.titleDeedFile) {
      //   formData.append("titleDeedFile", values?.titleDeedFile)
      // }

      if (ocrPropertyDetail?.titleDeedFile) {
        formData.append("titleDeedFile", ocrPropertyDetail.titleDeedFile);
      }
    }

    // CALLING API FOR SAVE FORM DATA
    if (isEdit) {
      EditIdentificationData(editFormData).then((response) => {
        navigate(`${nameBasedProtectedRoutes?.propertyEdit?.path}?tab=${2}&id=${response?.id}`, { replace: true })
        toast.success("Identification data updated.")
      }).catch((error) => {
        toast.error(error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data?.message)
      }).finally(() => {
        setLoading(false)
      })

    } else {
      SaveIdentifcationFormApi(formData).then((response) => {
        setLoading(false)
        setPropertyId(response?.id ?? null)

        navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${2}&id=${response?.id}`, { replace: true })

        setPrevTitleDeed(null)
        toast.success('Identification data saved.')
        if (propertyId) {
          getPropertyData()
        }
        // FOR MOVING ON NEXT TAB
        // handleNextStep(1)
      }).catch((error) => {
        setLoading(false)
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      })
    }

  }
  // FUNCTION FOR FETCH PROPERTY INFO
  const getPropertyInfo = () => {
    setLoading(true)
    PropertyInfoApi().then(response => {
      const offeringType = Object.keys(response?.propertyOfferingType ?? {}).map((key) => {
        return { value: key, label: response?.propertyOfferingType[key] }
      }
      );
      const propertyCategory = Object.keys(response?.propertyCategory ?? {}).map((key) => {
        return { value: key, label: response?.propertyCategory[key] }
      }
      );
      setPropertyInfo({ offeringType, propertyCategory })
      setInitialValues((prev) => ({ ...prev, amakenId: response?.amakenId }))
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error.response.data.error_description
        ? error.response.data.error_description
        : error.response.data?.message)
    })
  }
  // FUNCTION FOR FETCH PROPERTY TYPE LIST
  const getPropertyType = () => {
    setLoading(true)
    PropertyListApi().then(response => {
      const propTypes = response?.filter((element) => (element?.propertyCategory === selectedPropCategory && element?.status === true))
      setPropertyTypeList(propTypes)
      const selectedPropertyType = propTypes.find((element) => element?.id === propertyData?.propertyTypeId)
      if (selectedPropertyType) {
        setSelectedPropType(propertyData?.propertyTypeId)
      } else {
        setSelectedPropType('')
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error.response.data.error_description
        ? error.response.data.error_description
        : error.response.data?.message)
    })
  }
  // CALL FUNCTIONS FOR GET DATA FOR DROPDOWN LISTS
  useEffect(() => {
    getPropertyInfo()
  }, [])

  useEffect(() => {
    if (selectedPropCategory && selectedPropCategory !== '') {
      getPropertyType()
    }
  }, [selectedPropCategory])


  // IF PROPERTY ID AVAILABLE IN URL
  useEffect(() => {
    if (property_id) {
      setInitialValues({
        id: propertyId ?? null,
        buildingNumber: propertyData?.buildingNumber,
        floorNumber: propertyData?.floorNumber ?? '',
        apartmentId: propertyData?.apartmentId,
        land_type: propertyData?.land_type,
        propertyOfferingType: propertyData?.propertyOfferingType,
        propertyCategory: propertyData?.propertyCategory,
        propertyTypeId: propertyData?.propertyTypeId,
        priceInJOD: propertyData?.priceInJod,
        amakenId: propertyData?.amakenId,
        listing_date: propertyData?.listingDate,
        agencyRefNo: propertyData?.agencyRefNo ?? ''
      })
      setDlsFormData({
        governorateId: propertyData?.governorateId,
        directorateId: propertyData?.directorateId,
        villageId: propertyData?.villageId,
        villageCode: propertyData?.village?.villageCode,
        basin: propertyData?.basin,
        district: propertyData?.district,
        idNumber: propertyData?.idNumber
      })
      setCoordinatesData({
        dlsKey: propertyData?.dlsKey,
        coordinate: propertyData?.coordinate,
        wgs84: propertyData?.coordinateWgs84
      })
      setPrevTitleDeed(propertyData?.titleDeed)
      setSelectedPropertyCategory(propertyData?.propertyCategory)
    } else {
      setDlsFormData({})
      setCoordinatesData({})
      setInitialValues({
        id: null,
        titleDeedFile: "",
        buildingNumber: "",
        floorNumber: "",
        apartmentId: "",
        land_type: "",
        propertyOfferingType: '',
        propertyCategory: "",
        propertyTypeId: "",
        priceInJOD: "",
        agencyRefNo: "",
        amakenId: "",
        listing_date: moment().format('YYYY-MM-DD'),
      })
    }
  }, [property_id, propertyData])

  const handleFileOpen = () => {
    if (prevTitleDeed && prevTitleDeed !== null) {
      setViewFile(true)
    } else {
      toast.error("No file available!")
    }

  }


  return (
    <div className="ps-widget bgc-white bdrs12 p30 overflow-hidden position-relative">
      <LoaderMu loading={loading} />
      <h4 className="title fz17 mb30"> {t("PROPERTY IDENTIFICATION")}</h4>
      {/* FORM FOR GENERATE DLS KEY AND GET COORDINATES */}
      <GenerateDlsKeyForm dlsFormData={dlsFormData} setCoordinatesData={setCoordinatesData} setDlsFormData={setDlsFormData} setOcrPropertyDetail={setOcrPropertyDetail} isEdit={isEdit} />
      {/* IDENTIFICATION FORM SHOULD ONLY SHOW IF USER HAVE GENERATED DLS KEY */}
      {
        coordinatesData?.coordinate ?
          <Formik
            validationSchema={propertyIdentificationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              handleSubmit(values);
            }}>
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              setFieldError,
              touched,
              isValid,
              errors,
            }) => (
              <Form>
                <div className="form-style1">
                  <div className="row">
                    {
                      /* <div className="col-sm-6 col-xl-4">
                          <AgencyFileUpload
                            id="titleDeedFile"
                            accept=".jpg, .jpeg, .png, .pdf"
                            name="titleDeedFile"
                            label={t("TITLE DEED")}
                            handleChange={(event) => {
                              const uploadedFile = event?.target?.files[0];
                              const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
                              const isAllowedType = allowedFileTypes.includes(uploadedFile?.type);
                              const allowedFileSize = 1024 * 1024 * 10; // 10MB
                              const isAllowedSize = uploadedFile?.size <= allowedFileSize;
    
                              if (!isAllowedType) {
                                setFieldError("titleDeedFile", t("PHOTOS OR PDF MUST BE IN JPEG, PNG, OR PDF FORMAT"));
                              } else if (!isAllowedSize) {
                                setFieldError("titleDeedFile", t("YOU CAN UPLOAD FILE UP TO 10MB"));
                              } else {
                                setFieldValue("titleDeedFile", uploadedFile);
                              }
                            }}
                            error={errors?.titleDeedFile}
                            fileName={values?.titleDeedFile || ''}
                            className={`form-control p15 pl30 ud-btn2 ${touched?.titleDeedFile && errors?.titleDeedFile ? "error" : ""}`}
                          />
                          {prevTitleDeed && prevTitleDeed !== null ?
                            <button type="button" className="py-2 mb-3 ud-btn btn-white" onClick={handleFileOpen}>
                              <FiEye className="me-2" />
                              {t("TITLE DEED")}</button> : ""
                          }
                        </div> */
                    }

                    <div className="col-sm-6 col-xl-4">
                      {
                        ocrPropertyDetail.landType ? <AgencySelect
                          showSearch={true}
                          label={t("LAND TYPE")}
                          id="land_type"
                          placeholder={t("SELECT")}
                          disabled={true}
                          name="land_type"
                          handleChange={(value) => {
                            setFieldValue("land_type", ocrPropertyDetail.landType);
                          }}
                          handleBlur={handleBlur}
                          value={ocrPropertyDetail.landType}
                          className={`selectpicker form-control ${touched?.land_type && errors?.land_type
                            ? "error"
                            : ""
                            }`}
                          options={[{
                            value: ocrPropertyDetail.landType,
                            label: ocrPropertyDetail.landType,
                          }]}
                        />
                          : <AgencySelect
                            showSearch={true}
                            label={t("LAND TYPE")}
                            id="land_type"
                            placeholder={t("SELECT")}
                            disabled={true}
                            name="land_type"
                            handleChange={(value) => {
                              setFieldValue("land_type", value);
                            }}
                            handleBlur={handleBlur}
                            value={values?.land_type}
                            className={`selectpicker form-control ${touched?.land_type && errors?.land_type
                              ? "error"
                              : ""
                              }`}
                            options={governorateOption ?? []}
                          />
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="mb20 mt30">
                        <label className="heading-color ff-heading fw600 mb30">
                          {t("LOCATION ON THE MAP")}
                        </label>
                        {/* COMPONENT FOR MAP WITH MARKER */}
                        <MapMarker coordinateData={coordinatesData?.wgs84} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-xl-4">
                      <AgencyInputText
                        readOnly={true}
                        type="number"
                        label={t("LONGITUDE")}
                        id="longitude"
                        placeholder={t("LONGITUDE")}
                        name="longitude"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={coordinatesData?.wgs84?.x ?? ''}
                        className={`form-control ${touched?.longitude && errors?.longitude
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      <AgencyInputText
                        readOnly={true}
                        type="number"
                        label={t("LATITUDE")}
                        id="latitude"
                        placeholder={t("LATITUDE")}
                        name="latitude"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={coordinatesData?.wgs84?.y ?? ''}
                        className={`form-control ${touched?.latitude && errors?.latitude
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      <AgencySelect
                        label={t('OFFERING TYPE')}
                        id="propertyOfferingType"
                        placeholder={t("SELECT")}
                        name="propertyOfferingType"
                        handleChange={(value) =>
                          setFieldValue("propertyOfferingType", value)
                        }
                        handleBlur={handleBlur}
                        value={values?.propertyOfferingType}
                        className={`selectpicker form-control ${touched?.propertyOfferingType &&
                          errors?.propertyOfferingType
                          ? "error"
                          : ""
                          }`}
                        options={propertyInfo?.offeringType ?? []}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-xl-4">
                      <AgencySelect
                        label={t("PROPERTY CATEGORY")}
                        id="propertyCategory"
                        placeholder={t("SELECT")}
                        name="propertyCategory"
                        handleChange={(value) => {
                          setFieldValue("propertyCategory", value);
                          setSelectedPropertyCategory(value)
                          // setFieldValue("propertyTypeId", '');
                        }}
                        handleBlur={handleBlur}
                        value={values?.propertyCategory}
                        className={`selectpicker form-control ${touched?.propertyCategory &&
                          errors?.propertyCategory
                          ? "error"
                          : ""
                          }`}
                        options={propertyInfo?.propertyCategory ?? []}
                      />
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      <AgencySelect
                        label={t("PROPERTY TYPE")}
                        id="propertyTypeId"
                        placeholder={t("SELECT")}
                        name="propertyTypeId"
                        handleChange={(value) => {
                          setFieldValue("propertyTypeId", value);
                          setSelectedPropType(value)
                        }}
                        handleBlur={handleBlur}
                        value={selectedPropType ?? values?.propertyTypeId}
                        className={`selectpicker form-control ${touched?.propertyTypeId &&
                          errors?.propertyTypeId
                          ? "error"
                          : ""
                          }`}
                        options={propertyTypeList && propertyTypeList?.length > 0 ?
                          propertyTypeList.map((type) => (
                            { value: type?.id, label: type?.propertyType }
                          )) : []
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      <AgencyInputText
                        type="number"
                        label={t("PRICE IN JOD")}
                        id="priceInJOD"
                        placeholder={t("PRICE IN JOD")}
                        name="priceInJOD"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.priceInJOD}
                        className={`form-control ${touched?.priceInJOD && errors?.priceInJOD
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-xl-4">
                      {/* <AgencyDatePicker className="custom-datepicker"/> */}
                      <AgencyInputText
                        label={t("AGENCY REFERENCE")}
                        id="agencyRefNo"
                        placeholder={t("AGENCY REFERENCE")}
                        name="agencyRefNo"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.agencyRefNo}
                        className={`form-control ${touched?.agencyRefNo &&
                          errors?.agencyRefNo
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      {/* <AgencyDatePicker className="custom-datepicker"/> */}
                      <AgencyInputText
                        readOnly={true}
                        label={t("LISTING DATE")}
                        id="listing_date"
                        placeholder={t("LISTING DATE")}
                        name="listing_date"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.listing_date}
                        className={`form-control ${touched?.listing_date &&
                          errors?.listing_date
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      <AgencyInputText
                        type="text"
                        label={t("AMAKEN ID")}
                        id="amakenId"
                        placeholder={t("AMAKEN ID")}
                        name="amakenId"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.amakenId}
                        readOnly={true}
                        className={`form-control ${touched?.amakenId && errors?.amakenId
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-sm-flex justify-content-end">
                        <button
                          disabled={loading ?? false}
                          type="submit"
                          className="ud-btn btn-dark"
                          onClick={handleSubmit}
                        >
                          {t("NEXT STEP")}
                          <i className="fal fa-arrow-right-long" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>)}
          </Formik>
          : ""}

      <ViewFileModal filePath={prevTitleDeed} isFileOpen={viewFile} setIsFileOpen={setViewFile} />
    </div>
  );
}


